import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'; // Added useLocation
import { Box, VStack, Text, Progress, Center, Button, Heading } from '@chakra-ui/react';
import { MdStar } from 'react-icons/md';
import TaskItem from './TaskItem';
import { useTimer } from '../TimerContext';
import Confetti from 'react-confetti';
import BottomNavBar from './BottomNavBar';
import supabase from '../supabaseClient';
import { useAuth } from '../AuthContext';
import { incrementCount } from '../CountHandler';

const TaskListScreen = () => {
  const { tasks, setTasks, startTask, stopTask, completeDay, taskListResetTime, updateElapsedTimeInDatabase, activeTaskId, completionDates, setCompletionDates, ongoingProgress, setActiveTaskId } = useTimer();
  const navigate = useNavigate();
  const location = useLocation(); // Added
  const { user } = useAuth();
  const [progress, setProgress] = useState(0);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [totalDuration, setTotalDuration] = useState(0);

// TaskListScreen.js
// ... existing code ...

const fetchTasks = async () => {
  if (!user) return;

  const { data: fetchedTasks, error } = await supabase
    .from('tasks')
    .select('*') // Ensure persistent_count is selected
    .eq('user_id', user.id)
    .order('id', { ascending: true });

  if (error) {
    console.error('Error fetching tasks:', error);
    return;
  }

  const updatedTasks = fetchedTasks.map((task) => {
    let newUpdatedElapsedTime = task.elapsed_time || 0;
    if (task.start_time) {
      const startTime = new Date(task.start_time).getTime();
      const now = Date.now();
      const diffInSeconds = Math.floor((now - startTime) / 1000);
      newUpdatedElapsedTime += diffInSeconds;
    }

    const isPending = task.start_time !== null;
    const progressColorScheme = task.status === 'GOAL' ? 'green' : task.status === 'LIMIT' ? 'red' : 'purple';
    const countValue = task.count_value || 0;
    const persistentCount = task.persistent_count || 0; // ADD this line to store persistent_count

    // Set iconBgColor for COUNT tasks to blue
    const iconBgColor = task.status === 'GOAL' ? 'green.500' : task.status === 'LIMIT' ? 'red.500' : 'blue.500';

    let progressValue = 0;
    let isComplete = false;
    if (task.status === 'GOAL') {
      progressValue = Math.min((newUpdatedElapsedTime / (task.duration * 60)) * 100, 100);
      isComplete = progressValue >= 100;
    } else if (task.status === 'COUNT') {
      const fraction = countValue / (task.duration || 1);
      progressValue = Math.min(fraction * 100, 100);
      isComplete = fraction >= 1; // highlight green if completed
    } else {
      progressValue = Math.min((newUpdatedElapsedTime / (task.duration * 60)) * 100, 100);
      isComplete = progressValue >= 100;
    }

    return {
      id: task.id,
      title: task.task_name,
      duration: task.duration,
      status: task.status,
      elapsed_time: newUpdatedElapsedTime,
      time: new Date(newUpdatedElapsedTime * 1000).toISOString().substr(11, 8),
      persistent_time: task.persistent_time || 0,
      persistent_count: persistentCount, // Storing persistent_count
      isActive: activeTaskId === task.id,
      isPending,
      progressColorScheme,
      isComplete,
      progress: progressValue,
      calculateProgress,
      icon: task.icon || 'FaSun',
      iconBgColor: iconBgColor,
      count_value: countValue
    };
  });

  setTasks(updatedTasks);
};

// ... existing code ...

  useEffect(() => {
    fetchTasks();
  }, [user]);

  // Re-fetch tasks when route changes to ensure count is displayed
  useEffect(() => {
    fetchTasks();
  }, [location]); // Added

  useEffect(() => {
    console.log('Tasks have been updated:', tasks);
    
    // Compute overall progress including COUNT tasks (exclude LIMIT tasks)
    const relevantTasks = tasks.filter(t => t.status === 'GOAL' || t.status === 'COUNT');
    if (relevantTasks.length > 0) {
      let totalFraction = 0;
      relevantTasks.forEach((task) => {
        if (task.status === 'GOAL') {
          const fraction = Math.min(task.elapsed_time / (task.duration * 60), 1);
          totalFraction += fraction;
        } else if (task.status === 'COUNT') {
          const fraction = Math.min(task.count_value / task.duration, 1);
          totalFraction += fraction;
        }
      });
      const overallProgress = (totalFraction / relevantTasks.length) * 100;
      setProgress(overallProgress);
    } else {
      setProgress(0);
    }

    // For elapsedTime and totalDuration (GOAL tasks only)
    const goalTasks = tasks.filter(task => task.status === 'GOAL');
    const totalElapsed = goalTasks.reduce((acc, task) => {
      const capped = Math.min(task.elapsed_time, task.duration * 60);
      return acc + capped;
    }, 0);
    const totalDur = goalTasks.reduce((acc, task) => acc + (task.duration * 60), 0);
  
    setElapsedTime(totalElapsed / 60);
    setTotalDuration(totalDur / 60);
  }, [tasks]);
  
  const handleCompleteDay = async () => {
    stopTask();
  
    await new Promise(resolve => setTimeout(resolve, 100)); 
  
    if (activeTaskId !== null) {
      console.error("activeTaskId was not reset correctly!");
      setActiveTaskId(null);
    }
  
    setTasks((prevTasks) => 
      prevTasks.map((task) => {
        if (task.start_time) {
          console.error("Navigated active task was not reset correctly!");
          return {
            ...task,
            start_time: null,
            elapsed_time: 0,
            isActive: false,
          };
        }
        return task;
      })
    );
  
    // Update persistent_count and reset count_value
    await Promise.all(tasks.map(async (task) => {
      const updates = { start_time: null, elapsed_time: 0 };
  
      if (task.status === 'COUNT') {
        updates.count_value = 0; // Reset count_value for the new day
        updates.persistent_count = (task.persistent_count || 0) + (task.count_value || 0); // Add today's count_value to persistent_count
      }
  
      await supabase
        .from('tasks')
        .update(updates)
        .eq('id', task.id);
    }));
  
    await fetchTasks();
  
    completeDay();
  };

  const handleEdit = (task) => {
    const taskData = {
      id: task.id,
      title: task.title,
      duration: task.duration,
      status: task.status,
      elapsed_time: task.elapsed_time,
      time: task.time,
      persistent_time: task.persistent_time,
      isActive: task.isActive,
      isPending: task.isPending,
      icon: task.icon,
      iconBgColor: task.iconBgColor,
      progress: task.progress,
      count_value: task.count_value
    };
  
    navigate('/update', { state: { task: taskData } });
  };

  const handleAddTask = () => {
    navigate('/update');
  };

  const handleDeleteTask = async (taskId) => {
    try {
      const { error } = await supabase
        .from('tasks')
        .delete()
        .eq('id', taskId);

      if (error) throw error;

      setTasks((prevTasks) => prevTasks.filter((task) => task.id !== taskId));
      setCompletionDates(prev => {
        const newCompletionDates = { ...prev };
        delete newCompletionDates[taskId];
        return newCompletionDates;
      });
    } catch (error) {
      console.error('Failed to delete task:', error);
    }
  };

  const calculateProgress = (time, duration) => {
    const [hours, minutes, seconds] = time.split(':').map(Number);
    const elapsedTime = hours * 60 + minutes + seconds / 60;
    return (Math.min(elapsedTime, duration) / duration) * 100;
  };

  const handleIncrementCount = async (taskId) => {
    const newVal = await incrementCount(taskId);
    if (newVal !== null) {
      setTasks((prevTasks) =>
        prevTasks.map((t) => {
          if (t.id === taskId) {
            const fraction = t.duration > 0 ? Math.min(newVal / t.duration, 1) : 0;
            const newProgress = fraction * 100;
            return { ...t, count_value: newVal, progress: newProgress, isComplete: fraction >= 1 };
          }
          return t;
        })
      );
    }
  };

  const goalTasks = tasks.filter((task) => task.status === 'GOAL');
  const limitTasks = tasks.filter((task) => task.status === 'LIMIT');
  const countTasks = tasks.filter((task) => task.status === 'COUNT');

  return (
    <Box
      bgGradient="linear(to-r, black, purple.900)"
      color="white"
      minH="100vh"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      p={4}
      w="100vw"
    >
      {progress === 100 && <Confetti />}
      <VStack spacing={4} w="90%" maxW="md">
        <Center>
          <Box as={MdStar} color="gold" boxSize="1.5em" mr={2} />
          <Text fontSize="lg" fontWeight="bold">{progress === 100 ? '100% Complete' : `${progress.toFixed(2)}% Complete`}</Text>
        </Center>
        <Progress value={progress} size="lg" colorScheme="yellow" w="100%" />
        <Text fontSize="md" color="gray.400">{elapsedTime.toFixed(2)}/{totalDuration.toFixed(2)} minutes</Text>

        <Button
          bgGradient="linear(to-r, purple.500, red.500)"
          color="white"
          size="lg"
          width="100%"
          onClick={handleAddTask}
        >
          Add Task
        </Button>

        {goalTasks.length > 0 && (
          <>
            <Heading fontSize="xl" fontWeight="bold" alignSelf="flex-start" color="green.300">GOALS</Heading>
            <VStack spacing={4} w="100%" overflowY="auto" maxH="60vh">
              {goalTasks.map((task) => (
                <React.Fragment key={task.id}>
                  <TaskItem
                    task={task}
                    onStart={() => startTask(task.id)}
                    onStop={stopTask}
                    onEdit={() => handleEdit(task)}
                    onDelete={() => handleDeleteTask(task.id)}
                    onIncrement={() => handleIncrementCount(task.id)}
                    progress={task.progress}
                  />
                </React.Fragment>
              ))}
            </VStack>
          </>
        )}

        {limitTasks.length > 0 && (
          <>
            <Heading fontSize="xl" fontWeight="bold" alignSelf="flex-start" color="red.300">LIMIT</Heading>
            <VStack spacing={4} w="100%" overflowY="auto" maxH="60vh">
              {limitTasks.map((task) => (
                <React.Fragment key={task.id}>
                  <TaskItem
                    task={task}
                    onStart={() => startTask(task.id)}
                    onStop={stopTask}
                    onEdit={() => handleEdit(task)}
                    onDelete={() => handleDeleteTask(task.id)}
                    onIncrement={() => handleIncrementCount(task.id)}
                    progress={task.progress}
                  />
                </React.Fragment>
              ))}
            </VStack>
          </>
        )}

        {countTasks.length > 0 && (
          <>
            <Heading fontSize="xl" fontWeight="bold" alignSelf="flex-start" color="purple.300">COUNT</Heading>
            <VStack spacing={4} w="100%" overflowY="auto" maxH="60vh">
              {countTasks.map((task) => (
                <React.Fragment key={task.id}>
                  <TaskItem
                    task={task}
                    onStart={() => {}}
                    onStop={() => {}}
                    onEdit={() => handleEdit(task)}
                    onDelete={() => handleDeleteTask(task.id)}
                    onIncrement={() => handleIncrementCount(task.id)}
                    progress={task.progress} // Already calculated as fraction * 100
                  />
                </React.Fragment>
              ))}
            </VStack>
          </>
        )}

        <Button
          bgGradient="linear(to-r, purple.500, red.500)"
          color="white"
          size="lg"
          width="100%"
          onClick={handleCompleteDay}
        >
          Complete Day
        </Button>
      </VStack>
      <BottomNavBar />
    </Box>
  );
};

export default TaskListScreen;
