import React, { createContext, useContext, useState, useEffect, useRef } from 'react';
import supabase from './supabaseClient';
import { useAuth } from './AuthContext';

const TimerContext = createContext();

export const TimerProvider = ({ children }) => {
  const { user } = useAuth();
  const [tasks, setTasks] = useState([]);
  const [activeTaskId, setActiveTaskId] = useState(null);
  const [totalDaysCompleted, setTotalDaysCompleted] = useState(0);
  const [taskListResetTime, setTaskListResetTime] = useState({});
  const [summaryPersistentTime, setSummaryPersistentTime] = useState({});
  const [dailyProgress, setDailyProgress] = useState([]);
  const [ongoingProgress, setOngoingProgress] = useState(0); // May still be updated periodically, but we won't rely on it at complete time.
  const [goalCompletions, setGoalCompletions] = useState({});
  const [completionDates, setCompletionDates] = useState({});
  const timerRef = useRef(null);

  useEffect(() => {
    if (user) {
      resetState();
      fetchUserData(user.id);
    }
  }, [user]);

  const resetState = () => {
    setTasks([]);
    setActiveTaskId(null);
    setTotalDaysCompleted(0);
    setTaskListResetTime({});
    setSummaryPersistentTime({});
    setDailyProgress([]);
    setOngoingProgress(0);
    setGoalCompletions({});
    setCompletionDates({});
  };

  const fetchUserData = async (userId) => {
    try {
      const { data, error } = await supabase
        .from('user_progress')
        .select('total_days_completed, daily_progress, ongoing_progress, goal_completions')
        .eq('user_id', userId)
        .single();

      if (error) {
        console.error('Error fetching user data:', error);
        return;
      }

      if (data) {
        setTotalDaysCompleted(data.total_days_completed || 0);
        setDailyProgress(data.daily_progress || []);
        setOngoingProgress(data.ongoing_progress || 0);
        setGoalCompletions(data.goal_completions || {});
      }
    } catch (err) {
      console.error('Error fetching user data:', err);
    }
  };

  const fetchTaskss = async () => {
    if (!user) return;

    const { data: fetchedTasks, error } = await supabase
      .from('tasks')
      .select('*')
      .eq('user_id', user.id)
      .order('id', { ascending: true });

    if (error) {
      console.error('Error fetching tasks:', error);
      return;
    }

    const updatedTasks = fetchedTasks.map((task) => {
      let newUpdatedElapsedTime = task.elapsed_time || 0;

      if (task.start_time) {
        const startTime = new Date(task.start_time).getTime();
        const now = Date.now();
        const diffInSeconds = Math.floor((now - startTime) / 1000);
        newUpdatedElapsedTime += diffInSeconds;
      }

      const isPending = task.start_time !== null;
      const progress = Math.min((newUpdatedElapsedTime / (task.duration * 60)) * 100, 100);
      const isComplete = progress >= 100;
      const progressColorScheme = task.status === 'GOAL' ? 'green' : task.status === 'LIMIT' ? 'red' : 'purple';

      return {
        id: task.id,
        title: task.task_name,
        duration: task.duration,
        status: task.status,
        elapsed_time: newUpdatedElapsedTime,
        time: new Date(newUpdatedElapsedTime * 1000).toISOString().substr(11, 8),
        persistent_time: task.persistent_time || 0,
        isActive: activeTaskId === task.id,
        isPending,
        isComplete,
        progressColorScheme,
        progress,
        icon: task.icon || 'FaSun',
        iconBgColor: task.status === 'GOAL' ? 'green.500' : 'red.500',
      };
    });

    setTasks(updatedTasks);
  };

  const startTask = async (taskId) => {
    if (activeTaskId !== null && activeTaskId !== taskId) {
      stopTask(); 
    }

    const task = tasks.find((t) => t.id === taskId);
    let initialTimeInSeconds = task?.elapsed_time || 0;

    await supabase
      .from('tasks')
      .update({ start_time: null })
      .eq('id', taskId);

    setActiveTaskId(taskId);
    localStorage.setItem('task_id', taskId);

    setTaskListResetTime((prevTimes) => ({
      ...prevTimes,
      [task.id]: new Date(initialTimeInSeconds * 1000).toISOString().substr(11, 8),
    }));

    setTasks((prevTasks) =>
      prevTasks.map((t) =>
        t.id === taskId
          ? { ...t, isActive: true, time: new Date(initialTimeInSeconds * 1000).toISOString().substr(11, 8) }
          : t
      )
    );

    timerRef.current = setInterval(() => {
      initialTimeInSeconds += 1;
      const newTimeString = new Date(initialTimeInSeconds * 1000).toISOString().substr(11, 8);

      setTasks((prevTasks) =>
        prevTasks.map((t) => (t.id === taskId ? { ...t, time: newTimeString } : t))
      );
    }, 1000);
  };

  const stopTask = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }

    const task = tasks.find((task) => task.id === activeTaskId);
    if (task) {
      const [hours, minutes, seconds] = task.time.split(':').map(Number);
      const totalSeconds = hours * 3600 + minutes * 60 + seconds;

      updateElapsedTimeInDatabase(activeTaskId, totalSeconds);

      setTasks((prevTasks) =>
        prevTasks.map((t) =>
          t.id === activeTaskId ? { ...t, elapsed_time: totalSeconds, isActive: false, isPending: false } : t
        )
      );

      setActiveTaskId(null);
    }
  };

  const upgradedStopTask = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }

    const task = tasks.find((task) => task.id === activeTaskId);
    if (task) {
      const [hours, minutes, seconds] = task.time.split(':').map(Number);
      const totalSeconds = hours * 3600 + minutes * 60 + seconds;

      updateElapsedTimeInDatabase(activeTaskId, totalSeconds);

      setTasks((prevTasks) =>
        prevTasks.map((t) =>
          t.id === activeTaskId ? { ...t, elapsed_time: totalSeconds, isActive: false, isPending: false } : t
        )
      );

      setActiveTaskId(null);
      localStorage.removeItem('task_id');
    }
  };

  useEffect(() => {
    const handleVisibilityChange = async () => {
      if (document.hidden) {
        const taskId = localStorage.getItem('task_id');
        if (taskId) {
          const { data: currentTask, error } = await supabase
            .from('tasks')
            .select('start_time')
            .eq('id', taskId)
            .single();

          if (error) {
            console.error('Error fetching task:', error);
            return;
          }

          if (!currentTask.start_time) {
            const timestamp = new Date().toISOString();
            const { error: updateError } = await supabase
              .from('tasks')
              .update({ start_time: timestamp })
              .eq('id', taskId);

            if (updateError) {
              console.error('Error updating start_time:', updateError);
              return;
            }
          }
          stopTask();
        }
      } else {
        const taskId = localStorage.getItem('task_id');
        if (taskId) {
          await fetchTaskss();
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [stopTask, startTask, activeTaskId]);

  const updateElapsedTimeInDatabase = async (taskId, elapsedTime) => {
    try {
      const { error } = await supabase
        .from('tasks')
        .update({ elapsed_time: elapsedTime })
        .eq('id', taskId);

      if (error) {
        console.error('Error updating elapsed time:', error);
      } else {
        console.log(`Elapsed time for task ID ${taskId} updated to ${elapsedTime} seconds`);
      }
    } catch (err) {
      console.error('Error in updateElapsedTimeInDatabase:', err);
    }
  };

  useEffect(() => {
    if (activeTaskId !== null) {
      timerRef.current = setInterval(() => {
        setTasks((prevTasks) =>
          prevTasks.map((task) => {
            if (task.id === activeTaskId) {
              const [hours, minutes, seconds] = taskListResetTime[task.id]?.split(':').map(Number) || [0, 0, 0];
              const totalSeconds = hours * 3600 + minutes * 60 + (seconds || 0) + 1;

              if (!isNaN(totalSeconds)) {
                const newTimeString = new Date(totalSeconds * 1000).toISOString().substr(11, 8);

                setTaskListResetTime((prevTimes) => ({
                  ...prevTimes,
                  [task.id]: newTimeString,
                }));

                const [persistHours, persistMinutes, persistSeconds] = summaryPersistentTime[task.id]?.split(':').map(Number) || [0, 0, 0];
                const totalPersistSeconds = persistHours * 3600 + persistMinutes * 60 + (persistSeconds || 0) + 1;
                if (!isNaN(totalPersistSeconds)) {
                  const newPersistTimeString = new Date(totalPersistSeconds * 1000).toISOString().substr(11, 8);
                  setSummaryPersistentTime((prevTimes) => ({
                    ...prevTimes,
                    [task.id]: newPersistTimeString,
                  }));
                }

                updateTimeInDatabase(task.id, 1);
                return { ...task, time: newTimeString };
              }
            }
            return task;
          })
        );

const relevantTasks = tasks.filter((t) => t.status === 'GOAL' || t.status === 'COUNT');
if (relevantTasks.length > 0) {
  let totalFraction = 0;
  relevantTasks.forEach((task) => {
    if (task.status === 'GOAL') {
      const fraction = Math.min(task.elapsed_time / (task.duration * 60), 1);
      totalFraction += fraction;
    } else if (task.status === 'COUNT') {
      const fraction = Math.min(task.count_value / task.duration, 1);
      totalFraction += fraction;
    }
  });
  const overallProgress = (totalFraction / relevantTasks.length) * 100;
  setOngoingProgress(overallProgress);
} else {
  setOngoingProgress(0);
}
      }, 1000);
    } else if (timerRef.current) {
      clearInterval(timerRef.current);
    }

    return () => {
      clearInterval(timerRef.current);
    };
  }, [activeTaskId, tasks]);

  const updateTimeInDatabase = async (taskId, seconds) => {
    try {
      const { data, error } = await supabase
        .from('tasks')
        .select('elapsed_time')
        .eq('id', taskId)
        .single();

      if (error) {
        console.error('Error fetching task time:', error);
        return;
      }

      const currentElapsedTime = data.elapsed_time || 0;
      const newElapsedTime = currentElapsedTime + seconds;

      const { error: updateError } = await supabase
        .from('tasks')
        .update({ elapsed_time: newElapsedTime })
        .eq('id', taskId);

      if (updateError) console.error('Error updating task time:', updateError);
    } catch (err) {
      console.error('Error in updateTimeInDatabase:', err);
    }
  };

  const saveUserProgress = async (updatedDailyProgress, updatedTotalDaysCompleted, updatedGoalCompletions) => {
    try {
      if (!user || !user.id) {
        console.error("User ID is null or undefined, skipping saveUserProgress.");
        return;
      }

      console.log('Saving user progress with:', {
        updatedDailyProgress,
        updatedTotalDaysCompleted,
        updatedGoalCompletions
      });

      const { error } = await supabase
        .from('user_progress')
        .upsert({
          user_id: user.id,
          total_days_completed: updatedTotalDaysCompleted,
          daily_progress: updatedDailyProgress,
          // ongoing_progress is no longer critical here, but we can set it to 0 or currentProgress if needed
          ongoing_progress: 0, 
          goal_completions: updatedGoalCompletions,
        });

      if (error) {
        console.error('Error saving user progress data:', error);
      } else {
        console.log("User progress successfully saved.");
      }
    } catch (error) {
      console.error('Error in saveUserProgress:', error);
    }
  };

  const completeDay = async () => {
    // Recompute final daily progress from tasks at the moment of completion:
    const goalTasks = tasks.filter((t) => t.status === 'GOAL');
    const totalGoalTime = goalTasks.reduce((acc, t) => acc + (t.duration * 60), 0);
    const totalElapsed = goalTasks.reduce((acc, t) => {
      const [h, m, s] = taskListResetTime[t.id]?.split(':').map(Number) || [0, 0, 0];
      return acc + Math.min(h * 3600 + m * 60 + s, t.duration * 60);
    }, 0);
    const currentProgress = totalGoalTime > 0 ? (totalElapsed / totalGoalTime) * 100 : 0;

    // Compute final arrays and counts
    const newDailyProgress = [...dailyProgress, currentProgress];
    const newTotalDaysCompleted = totalDaysCompleted + 1;
    const newGoalCompletions = { ...goalCompletions };

    try {
      // Update tasks first
      const updates = tasks.map(async (task) => {
        const elapsedTime = task.elapsed_time || 0;
        const persistentTime = task.persistent_time || 0;
        const newPersistentTime = elapsedTime + persistentTime;

        if (task.status === 'GOAL' && elapsedTime >= task.duration * 60) {
          newGoalCompletions[task.id] = (newGoalCompletions[task.id] || 0) + 1;
        }

        const { error: updateError } = await supabase
          .from('tasks')
          .update({
            elapsed_time: 0,
            persistent_time: newPersistentTime,
          })
          .eq('id', task.id);

        if (updateError) {
          console.error('Error updating persistent_time and resetting elapsed_time:', updateError);
        } else {
          console.log(`Task ID ${task.id} persistent_time updated to ${newPersistentTime} seconds`);
        }
      });

      await Promise.all(updates);
    } catch (error) {
      console.error('Error completing day:', error);
    }

    // Save user progress with new computed values
    await saveUserProgress(newDailyProgress, newTotalDaysCompleted, newGoalCompletions);

    // Now update the state after saving
    setDailyProgress(newDailyProgress);
    setTotalDaysCompleted(newTotalDaysCompleted);
    setGoalCompletions(newGoalCompletions);
    setCompletionDates((prevDates) => prevDates + 1);

    setTaskListResetTime({});
    setTasks((prevTasks) =>
      prevTasks.map((task) => ({
        ...task,
        time: '00:00:00',
        isActive: false,
        elapsed_time: 0,
      }))
    );

    setActiveTaskId(null);
    setOngoingProgress(0);
    clearInterval(timerRef.current);
  };

  return (
    <TimerContext.Provider value={{
      tasks,
      setTasks,
      activeTaskId,
      startTask,
      stopTask,
      totalDaysCompleted,
      taskListResetTime,
      summaryPersistentTime,
      completeDay,
      dailyProgress,
      ongoingProgress,
      goalCompletions,
      completionDates,
      setCompletionDates,
      setTotalDaysCompleted,
      setDailyProgress,
      setOngoingProgress,
      setActiveTaskId,
      updateElapsedTimeInDatabase,
      setGoalCompletions,
      fetchTaskss,
      upgradedStopTask
    }}>
      {children}
    </TimerContext.Provider>
  );
};

export const useTimer = () => useContext(TimerContext);
