import React, { useState, useEffect } from 'react';
import {
  Box,
  VStack,
  Text,
  HStack,
  Divider,
  Progress,
  CircularProgress,
  CircularProgressLabel,
} from '@chakra-ui/react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import BottomNavBar from './BottomNavBar';
import { useTimer } from '../TimerContext';
import supabase from '../supabaseClient';
import { useAuth } from '../AuthContext';
import { useLocation } from 'react-router-dom';

const SummaryScreen = () => {
  const {
    tasks,
    totalDaysCompleted,
    setTotalDaysCompleted,
    dailyProgress,
    setDailyProgress,
    ongoingProgress,
    setOngoingProgress,
    goalCompletions,
    setGoalCompletions,
  } = useTimer();

  const { user } = useAuth();
  const location = useLocation();
  const [dayOffset, setDayOffset] = useState(0);
  const [totalTime, setTotalTime] = useState(0);
  const [loading, setLoading] = useState(true);

  const fetchProgressData = async () => {
    if (!user || !user.id) {
      setLoading(false);
      return;
    }

    try {
      const { data, error } = await supabase
        .from('user_progress')
        .select('total_days_completed, daily_progress, ongoing_progress, goal_completions')
        .eq('user_id', user.id)
        .single();

      if (error) {
        console.error('Error fetching progress data:', error);
        setLoading(false);
        return;
      }

      if (data) {
        if (data.total_days_completed !== undefined && data.total_days_completed !== null) {
          setTotalDaysCompleted(data.total_days_completed);
        }

        if (Array.isArray(data.daily_progress)) {
          setDailyProgress(data.daily_progress);
        }

        if (typeof data.ongoing_progress === 'number') {
          setOngoingProgress(data.ongoing_progress);
        }

        if (data.goal_completions && typeof data.goal_completions === 'object') {
          setGoalCompletions(data.goal_completions);
        }

        const totalDays = (data.total_days_completed !== undefined && data.total_days_completed !== null)
          ? data.total_days_completed
          : totalDaysCompleted;
        const initialOffset = totalDays >= 7 ? Math.floor((totalDays - 1) / 7) * 7 : 0;
        setDayOffset(initialOffset);
      } else {
        console.log('No user progress data found.');
      }
    } catch (err) {
      console.error('Error in fetchProgressData:', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProgressData();
  }, [user, location]); 

  useEffect(() => {
    const totalTrackedTime = tasks.reduce((acc, task) => {
      const totalPersistentTime = task.persistent_time || 0;
      return acc + totalPersistentTime;
    }, 0);
    setTotalTime(totalTrackedTime);
  }, [tasks]);

  const calculateTaskPercentage = (task) => {
    if (task.status === 'GOAL') {
      // GOAL tasks percentage based on persistent_time out of totalTime (original logic)
      if (totalTime === 0) return 0;
      const taskPersistentTime = task.persistent_time || 0;
      return (taskPersistentTime / totalTime) * 100;
    } else if (task.status === 'COUNT') {
      // COUNT tasks percentage match the fraction as on the TaskListScreen
      // min(count_value/duration, 1)*100
      const fraction = task.duration > 0 ? Math.min(task.count_value / task.duration, 1) : 0;
      return fraction * 100;
    } else {
      // LIMIT or others, no special logic needed for summary
      if (totalTime === 0) return 0;
      const taskPersistentTime = task.persistent_time || 0;
      return (taskPersistentTime / totalTime) * 100;
    }
  };

  const handleNextDays = () => {
    const maxOffset = Math.floor((totalDaysCompleted - 1) / 7) * 7;
    if (dayOffset < maxOffset) {
      setDayOffset(dayOffset + 7);
    }
  };

  const handlePrevDays = () => {
    if (dayOffset > 0) {
      setDayOffset(dayOffset - 7);
    }
  };

  const formatTime = (seconds) => {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hrs}h ${mins}m ${secs}s`;
  };

  return (
    <Box
      bgGradient="linear(to-r, black, purple.900)"
      color="white"
      minH="100vh"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="flex-start"
      p={6}
      pb={20}
    >
      {loading ? (
        <CircularProgress isIndeterminate color="green.300" />
      ) : (
        <VStack spacing={6} w="100%" maxW="md">
          <HStack w="100%" justifyContent="space-between">
            <VStack>
              <Text fontSize="xl" fontWeight="bold" color="green.500">
                Total Days Completed
              </Text>
              <Text fontSize="2xl">{totalDaysCompleted}</Text>
            </VStack>
            <VStack>
              <Text fontSize="xl" fontWeight="bold" color="blue.500">
                Total Time Duration
              </Text>
              <Text fontSize="2xl">
                {Math.floor(totalTime / 3600)} hrs {Math.floor((totalTime % 3600) / 60)} min
              </Text>
            </VStack>
          </HStack>
          <Divider />
          <HStack spacing={4} justifyContent="center" w="100%">
            {dayOffset > 0 && (
              <button onClick={handlePrevDays}>
                <FaArrowLeft />
              </button>
            )}
            {Array.from({ length: 7 }, (_, index) => index + 1 + dayOffset).map(
              (day, index) => (
                <CircularProgress
                  key={index}
                  value={dailyProgress[day - 1] || 0}
                  color="green.400"
                  size="40px"
                  thickness="12px"
                >
                  <CircularProgressLabel>{day}</CircularProgressLabel>
                </CircularProgress>
              )
            )}
            {totalDaysCompleted > dayOffset + 7 && (
              <button onClick={handleNextDays}>
                <FaArrowRight />
              </button>
            )}
          </HStack>
          <Divider />
          <VStack w="100%" spacing={4}>
            {tasks
              .sort((a, b) => {
                if (a.status === 'GOAL' && b.status !== 'GOAL') return -1;
                if (a.status !== 'GOAL' && b.status === 'GOAL') return 1;
                return 0;
              })
              .map((task) => (
                <Box key={task.id} w="100%" mb={4}>
                  {task.status === 'COUNT' ? (
                    <>
                      <Box display="grid" gridTemplateColumns="50% 50%" w="100%" alignItems="center">
                        <Text color="purple.500">{task.title}</Text>
                        <Text textAlign="right" justifySelf="right">
                          Count: {task.persistent_count}
                        </Text>
                      </Box>
                      {/* Show progress same as tasklistscreen: count_value/duration */}
                      <Progress
                        value={calculateTaskPercentage(task)}
                        size="lg"
                        colorScheme="purple"
                      />
                    </>
                  ) : (
                    <>
                      <Box display="grid" gridTemplateColumns="25% 25% 50%" w="100%" alignItems="center">
                        <Text color="purple.500">{task.title}</Text>
                        <Text textAlign="center" justifySelf="center">
                          {(goalCompletions[task.id] || 0)} days
                        </Text>
                        <Text textAlign="right" justifySelf="right">
                          {calculateTaskPercentage(task).toFixed(2)}% ({formatTime(task.persistent_time || 0)})
                        </Text>
                      </Box>
                      <Progress
                        value={calculateTaskPercentage(task)}
                        size="lg"
                        colorScheme={task.status === 'GOAL' ? 'green' : 'red'}
                      />
                    </>
                  )}
                </Box>
              ))}
          </VStack>
        </VStack>
      )}
      <BottomNavBar />
    </Box>
  );
};

export default SummaryScreen;

