import supabase from './supabaseClient';

export async function incrementCount(taskId) {
  try {
    // Fetch current count_value
    const { data, error: fetchError } = await supabase
      .from('tasks')
      .select('count_value')
      .eq('id', taskId)
      .single();
    if (fetchError) {
      console.error('Error fetching count_value:', fetchError);
      return null;
    }

    const currentValue = data.count_value || 0;
    const newValue = currentValue + 1;

    const { error: updateError } = await supabase
      .from('tasks')
      .update({ count_value: newValue })
      .eq('id', taskId);

    if (updateError) {
      console.error('Error updating count_value:', updateError);
      return null;
    }

    return newValue;
  } catch (err) {
    console.error('Error incrementing count_value:', err);
    return null;
  }
}
